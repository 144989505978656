import { forEach, get, isEmpty, join, map, slice, split, startsWith, trim } from 'lodash';
import {
  applyStainTypeIfRequired,
  bracketsRegex,
  buildClassifiersString,
  capitalization,
  getDictBrackets,
  getFormattedArea,
  getFormattedCellWithStain,
  getFormattedCellWithoutPositiveOrNegative,
  getFormattedClassificationModels,
  getFormattedStain,
  getFormattedText,
  getGridBasedString,
  getStatistic,
} from '.';
import { FormatBracketsOptions } from './formatBracketsOptions';

export const formatBracketsFeature = (key: string, bracketTypesOptions: FormatBracketsOptions): string => {
  try {
    const featureParts = slice(split(key, bracketsRegex), 1, -1);
    let family = '';
    forEach(featureParts, (featurePart) => {
      if (startsWith(featurePart, 'f:')) {
        family = split(featurePart, ':')[1];
      }
    });

    if (f[family]) {
      return capitalization(f[family](featureParts, bracketTypesOptions));
    } else {
      return key;
    }
  } catch (error) {
    console.error(`Error formatting feature: ${key}`, error);
    return key;
  }
};

export const f: Record<string, (featureParts: string[], options: FormatBracketsOptions) => string> = {
  '1': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);

    return applyStainTypeIfRequired(
      `${getFormattedArea(dictBrackets['a'], bracketTypesOptions.areaTypeOptions)} area`,
      dictBrackets['s'],
      bracketTypesOptions
    );
  },
  '2': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return applyStainTypeIfRequired(
      `${getFormattedArea(dictBrackets['numerator']['a'], areaTypeOptions)} to ${getFormattedArea(
        dictBrackets['denominator']['a'],
        areaTypeOptions
      )} ratio`,
      dictBrackets['s'],
      bracketTypesOptions
    );
  },
  '3': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['classifiers'], bracketTypesOptions)}count in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )}`;
  },
  '4': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    const { startWith, endWith, threshold } = getGridBasedString(dictBrackets);

    return `${startWith}${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['classifiers'],
      bracketTypesOptions
    )}fraction out of all cells${threshold} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}${endWith}`;
  },
  '5': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    const { startWith, endWith, threshold } = getGridBasedString(dictBrackets);

    return `${startWith}${getFormattedCellWithStain(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['numerator']['classifiers'],
      bracketTypesOptions
    )}to ${getFormattedCellWithStain(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['denominator']['classifiers'],
      bracketTypesOptions
    )}ratio${threshold} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}${endWith}`;
  },

  '6': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    const { startWith, endWith, threshold } = getGridBasedString(dictBrackets);

    return `${startWith}${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['classifiers'],
      bracketTypesOptions
    )}density${threshold} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}${endWith}`;
  },

  '7': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    const numeratorCell = `${getFormattedCellWithStain(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['numerator']['classifiers'], bracketTypesOptions)}`;

    const denominatorCell = `${getFormattedCellWithStain(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['denominator']['classifiers'], bracketTypesOptions)}`;

    if (numeratorCell === denominatorCell) {
      return `Fold change ${numeratorCell}fraction out of all cells in ${getFormattedArea(
        dictBrackets['numerator']['a'],
        areaTypeOptions
      )} vs. ${getFormattedArea(dictBrackets['denominator']['a'], areaTypeOptions)}`;
    }

    return `${numeratorCell}fraction out of all cells in ${getFormattedArea(
      dictBrackets['numerator']['a'],
      areaTypeOptions
    )} to ${denominatorCell}fraction out of all cells in ${getFormattedArea(
      dictBrackets['denominator']['a'],
      areaTypeOptions
    )} ratio`;
  },
  '8': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStain(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['numerator']['classifiers'],
      bracketTypesOptions
    )}density in ${getFormattedArea(dictBrackets['numerator']['a'], areaTypeOptions)} to ${getFormattedCellWithStain(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['denominator']['classifiers'],
      bracketTypesOptions
    )}density in ${getFormattedArea(dictBrackets['denominator']['a'], areaTypeOptions)} ratio`;
  },
  '9': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `mean percent ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}within ${
      dictBrackets['max_dist']
    } um of ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )}`;
  },
  '24': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}within ${
      dictBrackets['max_dist']
    } um of ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )}`;
  },
  '10': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}within ${
      dictBrackets['max_dist']
    } um of ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['numerator']['a'],
      areaTypeOptions
    )} to ${dictBrackets['reduction']} percent ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}within ${
      dictBrackets['max_dist']
    } um of ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['denominator']['a'],
      areaTypeOptions
    )} ratio`;
  },
  '11': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStain(
      dictBrackets['numerator']['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['numerator']['neighbor']['classifiers'], bracketTypesOptions)}within ${
      dictBrackets['max_dist']
    } um of ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )} to ${dictBrackets['reduction']} percent ${getFormattedCellWithStain(
      dictBrackets['denominator']['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['denominator']['neighbor']['classifiers'], bracketTypesOptions)}within ${
      dictBrackets['max_dist']
    } um of ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )} ratio`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '12': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}with at least ${
      dictBrackets['min_neighbors']
    } ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}neighbors within ${
      dictBrackets['max_dist']
    } um in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '13': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}with at least ${
      dictBrackets['min_neighbors']
    } ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}neighbors within ${
      dictBrackets['numerator']['max_dist']
    } um to ${dictBrackets['denominator']['max_dist']} um in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )} ratio`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '14': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}with at least ${
      dictBrackets['min_neighbors']
    } ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}neighbors within ${
      dictBrackets['max_dist']
    } um in ${getFormattedArea(dictBrackets['numerator']['a'], areaTypeOptions)} to ${getFormattedArea(
      dictBrackets['denominator']['a'],
      areaTypeOptions
    )} ratio`;
  },
  '15': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['classifiers'], bracketTypesOptions)}${
      dictBrackets['reduction']
    } nucleus ${getFormattedText(dictBrackets['f_type'])} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '16': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions, areaTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStain(
      dictBrackets['source_s']?.['subset']['c'],
      dictBrackets['source_s']?.['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['source_s']?.['subset']['classifiers'],
      bracketTypesOptions
    )}and ${getFormattedCellWithStain(
      dictBrackets['target_s']['subset']['c'],
      dictBrackets['target_s']['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['target_s']['subset']['classifiers'],
      bracketTypesOptions
    )}colocalization by joint density (sigma: ${dictBrackets['sigma']}, thresh: ${
      dictBrackets['thresh']
    }) in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '17': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithoutPositiveOrNegative(dictBrackets['subset']?.['c'])} ${buildClassifiersString(
      dictBrackets['subset']?.['classifiers'],
      bracketTypesOptions
    )}coexpression of (${getFormattedStain(dictBrackets['source_s']?.['s'], stainTypeOptions)}+, ${getFormattedStain(
      dictBrackets['target_s']['s'],
      stainTypeOptions
    )}+) by joint density (sigma: ${dictBrackets['sigma']}, thresh: ${dictBrackets['thresh']})`;
  },
  '18': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions } = bracketTypesOptions;

    const sourcesStain = map(dictBrackets['sources_s'], (source_s) => {
      return `${getFormattedStain(source_s, stainTypeOptions)}+`;
    });

    return `${getFormattedCellWithoutPositiveOrNegative(dictBrackets['subset']?.['c'])} ${buildClassifiersString(
      dictBrackets['subset']?.['classifiers'],
      bracketTypesOptions
    )}coexpression of (${join(sourcesStain, ', ')}, ${getFormattedStain(
      dictBrackets['target_s']['s'],
      stainTypeOptions
    )}+) by joint density (sigma: ${dictBrackets['sigma']}, thresh: ${dictBrackets['thresh']})`;
  },
  '19': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithoutPositiveOrNegative(
      dictBrackets['numerator']['subset']['c']
    )} ${buildClassifiersString(
      dictBrackets['numerator']['subset']['classifiers'],
      bracketTypesOptions
    )}coexpression of (${getFormattedStain(
      dictBrackets['numerator']['source_s']['s'],
      stainTypeOptions
    )}+, ${getFormattedStain(
      dictBrackets['numerator']['target_s']['s'],
      stainTypeOptions
    )}+) by joint density to ${getFormattedCellWithoutPositiveOrNegative(
      dictBrackets['denominator']['subset']['c']
    )} ${buildClassifiersString(
      dictBrackets['denominator']['subset']['classifiers'],
      bracketTypesOptions
    )}coexpression of (${getFormattedStain(
      dictBrackets['denominator']['source_s']['s'],
      stainTypeOptions
    )}+, ${getFormattedStain(
      dictBrackets['denominator']['target_s']['s'],
      stainTypeOptions
    )}+) by joint density ratio (sigma: ${dictBrackets['sigma']}, thresh: ${dictBrackets['thresh']})`;
  },
  '20': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['neighbor']['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}within ${
      dictBrackets['max_dist']
    } um of ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['target']['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )}`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '21': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['target']['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['target']['classifiers'], bracketTypesOptions)}with at least ${
      dictBrackets['min_neighbors']
    } ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['neighbor']['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}neighbors within ${
      dictBrackets['max_dist']
    } um in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '23': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStain(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['numerator']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['numerator']['a'],
      areaTypeOptions
    )} to ${getFormattedCellWithStain(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['denominator']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['denominator']['a'],
      areaTypeOptions
    )} ratio`;
  },
  '25': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `shannon diversity index for ${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )}`;
  },
  '26': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `clark evans index for ${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )}`;
  },
  '27': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `geary's c for ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )}to ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )} (distance method: ${getFormattedText(dictBrackets['distance_method'])}, max distance: ${
      dictBrackets['max_dist']
    } um)`;
  },
  '28': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `moran's i ${getStatistic(dictBrackets['statistic'])}for ${getFormattedCellWithStain(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )}to ${getFormattedCellWithStain(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['neighbor']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )} (distance method: ${getFormattedText(dictBrackets['distance_method'])}, max distance: ${
      dictBrackets['max_dist']
    } um)`;
  },
  '29': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `ripley's ${dictBrackets['f_type']} function for ${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )} (cell radius: ${dictBrackets['cell_radius']} um, max distance: ${dictBrackets['max_dist']} um)`;
  },
  '30': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `count of ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} region instances`;
  },
  '32': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions, classificationModelOptions } = bracketTypesOptions;
    const formattedClassificationModels =
      dictBrackets['cm'] && dictBrackets['cm'] !== 'intensity'
        ? ` (${getFormattedClassificationModels(dictBrackets['cm'], classificationModelOptions)})`
        : '';

    const classifiersString = isEmpty(dictBrackets?.['pos']?.['classifiers'])
      ? ''
      : ` ${trim(buildClassifiersString(dictBrackets['pos']['classifiers'], bracketTypesOptions))}`;

    return `H score ${getFormattedCellWithStain(
      dictBrackets['pos']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )}${classifiersString}${formattedClassificationModels}`;
  },
  '33': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return applyStainTypeIfRequired(
      `${getFormattedCellWithoutPositiveOrNegative(dictBrackets['c'])} nuclear to cytoplasm ratio in ${getFormattedArea(
        dictBrackets['a'],
        areaTypeOptions
      )}`,
      dictBrackets['s'],
      bracketTypesOptions
    );
  },
  '34': (featureParts, bracketTypesOptions) => {
    // TODO: add test for this case (and 37, which relies on it) and fix accordingly - auto generated code
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStain(
      dictBrackets['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['classifiers'],
      bracketTypesOptions
    )}density by distance to ${getFormattedArea(
      get(dictBrackets, 'boundary.a'),
      areaTypeOptions
    )} boundary in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} (interval: ${
      dictBrackets['interval_size']
    } um)`;
  },
  '35': (featureParts, bracketTypesOptions) => {
    return f['6'](featureParts, bracketTypesOptions);
  },
  '36': (featureParts, bracketTypesOptions) => {
    return f['8'](featureParts, bracketTypesOptions);
  },
  '37': (featureParts, bracketTypesOptions) => {
    return f['34'](featureParts, bracketTypesOptions);
  },
  '38': (featureParts, bracketTypesOptions) => {
    return f['1'](featureParts, bracketTypesOptions);
  },
  '39': (featureParts, bracketTypesOptions) => {
    return f['12'](featureParts, bracketTypesOptions);
  },
  '40': (featureParts, bracketTypesOptions) => {
    return f['13'](featureParts, bracketTypesOptions);
  },
  '41': (featureParts, bracketTypesOptions) => {
    return f['14'](featureParts, bracketTypesOptions);
  },
  '42': (featureParts, bracketTypesOptions) => {
    return f['21'](featureParts, bracketTypesOptions);
  },
  '43': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions, stainTypeOptions } = bracketTypesOptions;

    // If all the details are tumor, show only the positive cell numerator
    if (
      dictBrackets['numerator']['neg']['c'] === 'tumor_cell-negative' &&
      (dictBrackets['denominator']['c'] === 'tumor_cell' ||
        dictBrackets['denominator']['c'] === 'tumor_cell-negative|tumor_cell-positive') &&
      dictBrackets['a'] === '1'
    ) {
      return `SPS for ${getFormattedCellWithStain(
        dictBrackets['numerator']['pos']['c'],
        dictBrackets['s'],
        stainTypeOptions
      )} ${buildClassifiersString(dictBrackets['numerator']['pos']['classifiers'], bracketTypesOptions)}(max radios: ${
        dictBrackets['max_dist']
      }, min neighbors: ${dictBrackets['min_neighbors']})`;
    }

    return `SPS for ${getFormattedCellWithStain(
      dictBrackets['numerator']['pos']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(
      dictBrackets['numerator']['pos']['classifiers'],
      bracketTypesOptions
    )}with ${getFormattedCellWithStain(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )} ${buildClassifiersString(dictBrackets['denominator']['classifiers'], bracketTypesOptions)}in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )} (numerator neg cells: ${getFormattedCellWithStain(
      dictBrackets['numerator']['neg']['c'],
      dictBrackets['s'],
      stainTypeOptions
    )}, max radios: ${dictBrackets['max_dist']}, min neighbors: ${dictBrackets['min_neighbors']})`;
  },
};

export const getFeatureNameBrackets = (key: string) => {
  const featureParts = slice(split(key, bracketsRegex), 1, -1);
  return getDictBrackets(featureParts);
};
